.form-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.form-container label {
  margin-bottom: -8px;
  z-index: 1;
  background-color: #fff;
  width: fit-content;
  margin-left: 10px;
  color: #222;
}

.form-container input,
.form-container select {
  padding: 8px 12px;
  height: 50px;
  font-size: 20px;
}

.form-container textarea {
  min-height: 100px;
  max-height: 500px;
  resize: vertical;
  padding: 8px 12px;
  font-size: 20px;
}

.form-container input:disabled {
  cursor: not-allowed;
  color: #444;
  background-color: #efefef;
}

.form-container span {
  font-size: 12px;
  padding: 2px 0 0 2px;
  font-style: italic;
  color: rgb(163, 0, 0);
}
