.contact {
  max-width: 1000px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact h2 {
  text-align: left;
  margin-right: auto;
}

.contact > * {
  width: 100%;
}

.contact-btn-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.contact-btn-container button {
  border: 1px solid #ddd;
  background-color: #fff;
  padding: 8px 12px;
  border-radius: 5px;
  font-size: 18px;
  position: relative;
}

.contact-btn-container button svg {
  margin-bottom: -3px;
}

.contact-btn-container button:disabled,
.contact-btn-container button:disabled:hover {
  cursor: not-allowed !important;
  background-color: #aaa;
  border-color: #aaa;
  color: #555;
}

.contact-btn-container button:hover {
  background-color: #eee;
  cursor: pointer;
}

.contact .isSending {
  padding-right: 25px;
}

.contact .loading-single {
  position: absolute;
  top: 0;
  right: 0;
  width: 25px;
  height: 25px;
  margin-right: 8px;
  margin-top: 7.5px;
}