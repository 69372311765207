.file-drop-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 150px;
}

.selected-files {
  width: 150px;
  height: 100%;
  padding: 10px 20px;
}

.selected-files .swiper {
  height: 100%;
}

.file-drop {
  border: 3px dashed rgb(218, 218, 218);
  padding: 15px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.file-drop:hover {
  cursor: pointer;
  background-color: rgba(247, 247, 247, 0.61);
}

.file-dropzone {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.file-dropzone u {
  font-weight: 600;
  padding-bottom: 1px;
  text-decoration: none;
  border-bottom: 1px solid #222;
}

.form-file-icon {
  margin-bottom: 10px;
  font-size: 40px;
}

.file-drop-form {
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
}

.file-drop-form label {
  margin: 0 0 10px 10px;
}

.file-drop-form > span {
  font-size: 12px;
  font-style: italic;
}

.file-drop-file {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.file-drop-file-icon {
  height: 60px;
  width: 60px;
}

.file-drop-file span {
    font-size: 12px;
    margin-top: 20px;
    text-overflow: ellipsis;
}
