*,
html,
body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
  scroll-behavior: smooth;
  color: #222;
  scroll-padding-top: 60px;
}

#root {
  height: 100%;
  width: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  margin-top: 60px;
}
