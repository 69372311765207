.header-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  border-bottom: 1px solid #ddd;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  z-index: 100;
  padding: 0 20px;
}

.header-body {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1500px;
  height: 60px;
}

.header-logo a {
  text-decoration: none;
  font-size: 22px;
  letter-spacing: 1px;
}

.header-logo span:hover {
  cursor: pointer;
}

.header-links {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.header-links a:not(:first-child) {
  margin-left: 20px;
}

.header-links a {
  text-decoration: none;
  padding: 8px 12px;
  border-radius: 5px;
}

.header-links a:hover {
  background-color: #e9e9e9;
}

.burger {
  height: 40px;
  width: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-evenly;
  margin-left: 50px;
}

.burger .line1 {
  width: 30px;
  height: 1px;
  background-color: #222;
  transition: all 0.3s ease;
}

.burger .line2,
.burger .line3 {
  width: 40px;
  height: 1px;
  background-color: #222;
  transition: all 0.3s ease;
}

.burger:hover,
.burger.active:hover {
  cursor: pointer;
}

.burger.active .line1 {
  width: 40px;
  transition: all 0.3s ease;
  transform: rotate(-45deg) translate(-8px, 8px);
}

.burger.active .line3 {
  width: 40px;
  transition: all 0.3s ease;
  transform: rotate(45deg) translate(-5.5px, -5.5px);
}

.burger.active .line2 {
  transition: width 0.3s ease;
  width: 0;
}

.header-additional {
  animation: slideFromLeftOut 0.4s forwards ease;
}
.header-additional.active-links {
  animation: slideFromLeftIn 0.4s forwards ease;
}

.header-additional {
  position: fixed;
  background-color: rgba(255, 255, 255, 0.925);
  backdrop-filter: blur(15px);
  top: 60px;
  right: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
}

.header-additional a {
  font-weight: bold;
  display: flex;
  align-items: center;
  padding: 12px 10px;
  height: 50px;
  width: 100%;
  text-transform: uppercase;
  font-weight: bold;
  text-decoration: none;
  font-size: 16px;
  margin-bottom: 5px;
  transition: background-color 0.2s ease;
}

.header-additional a:hover {
  background-color: #e9e9e9;
}

@media screen and (max-width: 900px) {
  /* .header-body {
    width: 95%;
  } */
}

@media screen and (max-width: 460px) {
  .burger {
    margin-left: 0;
  }
  .header-links {
    width: fit-content;
  }
}

@keyframes slideFromLeftIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideFromLeftOut {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}
