.home-main {
  min-height: 50vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: rgb(208, 241, 244);
  background: linear-gradient(
    25deg,
    rgba(208, 241, 244, 1) 0%,
    rgba(98, 191, 246, 1) 47%,
    rgba(47, 138, 157, 1) 100%
  );
  padding: 50px;
}

.home-main > div {
  display: flex;
  align-items: center;
  max-width: 1500px;
}

.home-main img {
  width: 100%;
  max-height: 450px;
  backdrop-filter: blur(15px);
  background-color: rgba(255, 255, 255, 0.247);
  padding: 30px 100px 30px 30px;
}

.home-main div div {
  padding: 50px 40px;
  max-width: 600px;
  width: 100%;
  color: #fff;
  background-color: #222;
  margin-left: -50px;
  z-index: 1;
}

.home-main h1 {
  font-size: 40px;
  color: #ffff;
  margin-bottom: 20px;
}

.home-main p {
  font-size: 18px;
  color: #ddd;
}

.home-intro {
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
}

.home-intro > div:nth-child(2n) {
  background-color: #f9f9f9;
}

.home-about {
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-about div {
  width: 100% !important;
  max-width: 1000px !important;
}

.home-about > div {
  width: 50%;
  display: flex;
  justify-content: center;
  max-width: 750px;
}

.home-about > div:first-child img {
  padding: 20px;
  width: auto;
  height: auto;
  object-fit: contain;
  max-width: 300px;
}

.home-about > div:last-child {
  display: flex;
  flex-direction: column;
}

.home-about > div:last-child p {
  font-size: 20px;
  line-height: 35px;
}

.home h2 {
  font-size: 30px;
  margin-bottom: 50px;
  border-bottom: 1px solid #222;
  width: fit-content;
  padding: 0 50px 5px 10px;
}

.home-intro > div {
  padding: 100px 50px;
}

.home-why {
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-intro > div > div > * {
  max-width: 1000px;
}

.home-why > div {
  width: fit-content;
  min-height: 40vh;
  display: flex;
  justify-content: center;
  max-width: 1500px;
}

.home-why > div:first-child {
  display: flex;
  flex-direction: column;
}

.home-why > div p {
  font-size: 20px;
  line-height: 28px;
}

.home-why ul {
  margin-top: 20px;
  padding-left: 50px;
}

.home-why ul li {
  list-style: none;
  margin: 0;
  font-size: 18px;
  line-height: 30px;
  font-weight: 200;
  display: flex;
  flex-direction: row;
  /* align-items: center; */
}

.home-why ul li:before {
  content: "";
  margin-top: 11px;
  transform: skewX(-13deg) !important;
  display: inline-block;
  height: 6px;
  width: 6px;
  min-width: 6px;
  max-width: 6px;
  min-height: 6px;
  max-height: 6px;
  margin-right: 20px;
  background-color: #333;
}

.home-link {
  background-color: #06f;
  color: #fff;
  font-size: 24px;
  position: sticky;
  bottom: 20px;
  right: 0;
  margin-left: auto;
  margin-right: 20px;
  margin-bottom: 20px;
  padding: 10px 20px;
  border-radius: 5px;
  max-width: fit-content;
  text-decoration: none;
  z-index: 2;
}

@media screen and (max-width: 1000px) {
  .home-main > div {
    flex-direction: column;
  }
  .home-main div div {
    margin: -50px 20px 20px 20px;
  }
  .home-main img {
    padding: 30px 30px 100px 30px;
  }
}

@media screen and (max-width: 850px) {
  .home-about {
    flex-direction: column-reverse;
  }

  .home-about > div {
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .home-main {
    padding: 20px;
  }
  .home-intro > div {
    padding: 50px 20px;
  }
  .home-why ul {
    padding-left: 15px;
  }
}
