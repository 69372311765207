.footer-container * {
  color: #eee !important;
}

.footer-container {
  min-height: 400px;
  background-color: #222;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px 50px;
}

.footer-body {
  display: flex;
  flex-direction: row;
  max-width: 1000px;
  padding: 25px 0;
  width: 100%;
}

.footer-body > div {
  margin-right: 100px;
  min-width: 200px;
  max-width: 400px;
}

.footer-intro {
  line-height: 20px;
  font-weight: lighter;
}

.footer-intro p {
  line-height: 35px;
}

.footer-links div {
  display: flex;
  flex-direction: column;
}

.footer-links div a {
  padding: 8px 10px;
  text-decoration: none;
  width: fit-content;
  border-radius: 5px;
}

.footer-links div a:hover {
  background-color: #444;
}

.footer-title {
  height: 40px;
  display: flex;
  align-items: flex-end;
  margin: 10px 0 20px 0;
  letter-spacing: 1px;
  font-weight: bold;
  text-transform: uppercase;
  border-bottom: 1px solid #eee;
  padding-bottom: 15px;
}

.footer-copyright {
  width: 100%;
  padding: 20px 20px 0 0;
  border-top: 1px solid #444;
  display: flex;
  justify-content: center;
}

.footer-copyright-body {
  width: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 900px) {
  .footer-body {
    flex-direction: column;
  }
  .footer-body > div {
    margin: 0 0 50px 0;
  }
}

@media screen and (max-width: 600px) {
  .footer-container {
    padding: 20px
  }
}