.formular.container {
  min-height: calc(100vh - 60px);
  align-items: center;
}

.formular-main {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1500px;
  height: 100%;
  padding: 20px;
  position: relative;
  box-sizing: border-box;
}

.formular-progress {
  position: sticky;
  top: 60px;
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 20px 0;
  background-color: #fff;
  z-index: 10;
}

.formular-button-container {
  bottom: 0;
  height: 70px;
  width: 100%;
  z-index: 1;
  position: fixed;
  background-color: #fff;
  padding: 10px 20px;
  border-top: 1px solid #eee;
  display: flex;
  justify-content: center;
}

.formular-button-body {
  max-width: 1460px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.formular-button-container button {
  appearance: button;
  backface-visibility: hidden;
  background-color: #17639e;
  border-radius: 6px;
  border-width: 0;
  box-shadow: rgba(50, 50, 93, 0.1) 0 0 0 1px inset,
    rgba(50, 50, 93, 0.1) 0 2px 5px 0, rgba(0, 0, 0, 0.07) 0 1px 1px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-size: 100%;
  height: 44px;
  line-height: 1.15;
  max-width: 150px;
  outline: none;
  overflow: hidden;
  padding: 0 25px;
  position: relative;
  text-align: center;
  text-transform: none;
  transform: translateZ(0);
  transition: all 0.2s, box-shadow 0.08s ease-in;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 100%;
}

.formular-button-container button:focus {
  box-shadow: rgba(50, 50, 93, 0.1) 0 0 0 1px inset,
    rgba(50, 50, 93, 0.2) 0 6px 15px 0, rgba(0, 0, 0, 0.1) 0 2px 2px 0,
    rgba(50, 151, 211, 0.3) 0 0 0 4px;
}

.formular-button-container button:disabled {
  cursor: not-allowed;
  background-color: #052136;
  color: #555;
}

.formular-form-container {
  margin-top: 3rem;
  margin-bottom: 100px;
}

.formular-form-container h1 {
  color: #222;
  font-size: 24px;
  padding: 0 0 20px 0;
  margin-bottom: 40px;
  border-bottom: 1px solid #eee;
}

.file-form-container h2 {
  border-bottom: 1px solid #d9d9d9;
}

.income-body .file-drop-form,
.deductions-body .file-drop-form,
.income-body .form-container,
.deductions-body .form-container {
  width: 100%;
}

.income h2,
.deductions h2 {
  padding: 20px 10px;
  background-color: #efefef;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.income h2:hover,
.deductions h2:hover {
  cursor: pointer;
  background-color: #e9e9e9;
}

.income-body,
.deductions-body {
  display: flex;
  flex-direction: column;
  transform-origin: top;
  background-color: #f9f9f9;
  padding: 20px;
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
}

.income .form-container label,
.deductions .form-container label {
  background-color: #f9f9f9;
}

.child {
  background-color: #f1f1f1;
  padding: 20px;
  border-bottom: 1px solid #fff;
  position: relative;
}

.remove-child {
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px 10px 0 0;
  padding: 8px 12px;
}

.child h2 {
  margin-bottom: 20px;
}

.child .form-container label {
  background-color: #f1f1f1;
}

.add-child {
  margin: 10px 0;
  width: 100%;
  padding: 12px 10px;
  cursor: pointer;
}

.formular-sending {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(194, 194, 194, 0.556);
  backdrop-filter: blur(10px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.formular-sending span {
  margin-top: 35px;
  font-size: 18px;
}

.formular-sending .loading-single {
  width: 40px;
  height: 40px;
}

@media screen and (max-width: 1000px) {
  .formular-main {
    width: 100%;
  }
}
